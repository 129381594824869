import React from "react";
import {Box, createStyles, MenuItem, Theme,} from "@material-ui/core";
import FormTextInput, {TickAdornment} from "../../../shared/components/FormTextInput";
import AscendButton from "../../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {withTitle} from "../../../shared/components/withTitle";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {QuoteActionTypes} from "../../MedicareAdvantage/QuoteContextProvider";
import AscendTitle from "../../../shared/components/AscendTitle";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useCommonStyles from "../../../shared/useCommonStyles";
import moment from "moment";
import AscendInfo from "../../../shared/components/AscendInfo";
import FormSelectInput from "../../../shared/components/FormSelectInput";
import {AppStateContext, IMedicareType} from "../../../shared/AppStateContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 40,
      maxWidth: 320,
    },
    input: {
      paddingRight: 0
    }
  }),
);

const formName = 'STEP_3';

type Step3Props = {quote: any, dispatch: any, onNextStep: () => void, onPrevStep: () => void};

function Step3({quote, dispatch, ...props}: Step3Props) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    getStoredUser()?.birthDate ? moment(getStoredUser()?.birthDate).toDate() : null
  );
  const [gender, setGender] = React.useState(getStoredUser()?.gender);
  const [tobacco, setTobacco] = React.useState(getStoredUser()?.tobacco);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    if (moment(selectedDate).isValid() && moment(selectedDate).year() > 1900) {
      setStoredUser({...getStoredUser(), birthDate: moment(selectedDate).format('MM/DD/YYYY'), gender, tobacco})
      dispatch({
        type: QuoteActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'STEP_3', form: formName, gender, tobacco}
      })
      props.onNextStep();
    }
  };

  const disabled = appState.medicareType === IMedicareType.MG ?
    !moment(selectedDate).isValid() || moment(selectedDate).year() <= 1900 || !tobacco || !gender :
    !moment(selectedDate).isValid() || moment(selectedDate).year() <= 1900;

  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <Box className={classes.container}>
      <AscendTitle title={'Date of birth'} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          disableFuture
          autoFocus={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              onSubmit();
            }
          }}
          InputAdornmentProps={{
            classes: {
              root: moment(selectedDate).isValid() && moment(selectedDate).year() >= 1900 ? undefined : commonClasses.hidden
            }
          }}
          InputProps={{
            classes: {
              root: classes.input
            }
          }}
          keyboardIcon={<TickAdornment className={'mr-15'}/>}
          placeholder={'MM / DD / YYYY'}
          value={selectedDate}
          invalidDateMessage={'Please enter a valid date of birth'}
          maxDateMessage={'Please enter a valid date of birth'}
          minDateMessage={'Please enter a valid date of birth'}
          onChange={setSelectedDate}
          TextFieldComponent={FormTextInput as any}
        />
      </MuiPickersUtilsProvider>

      {appState.medicareType === IMedicareType.MG && <>
        <FormSelectInput
          fullWidth
          label={'Gender'}
          value={gender}
          placeholder={'Select gender'}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setGender(event.target.value as string);
          }}
        >
          <MenuItem value={'M'}>Male</MenuItem>
          <MenuItem value={'F'}>Female</MenuItem>
        </FormSelectInput>
        <FormSelectInput
          fullWidth
          label={'Do you use tobacco products?'}
          value={tobacco}
          placeholder={'Select tobacco status'}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTobacco(event.target.value as string);
          }}
        >
          <MenuItem value={'false'}>No</MenuItem>
          <MenuItem value={'true'}>Yes</MenuItem>
        </FormSelectInput>
      </>}

      <AscendInfo open={appState.medicareType === IMedicareType.MG}
                  description={`Medicare Supplement prices can vary based on your age, gender and use of tobacco.`}/>
    </Box>

    <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
      <AscendButton className={'gtm-step-event'} id={formName}
                    disabled={disabled}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <AscendButton variant={'contained'} onClick={() => {
          props.onPrevStep()
        }} color={'secondary'}>Back</AscendButton>
      </Box>
    </Box>
  </Box>
}

export default withTitle(
  Step3,
  'What is your date of birth?',
  '',
  undefined,
  undefined,
  isMobile => isMobile,
)
