import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { from, split } from 'apollo-link';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENROLLMENT_GRAPHQL,
  headers: {
    "content-type": "application/json",
  }
});

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

const elink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

const requestLink =
  from([
    elink,
    httpLink,
  ])
;

export const client = new ApolloClient({
  link: requestLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});
