import React from "react";
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {GraphQLErrorType} from "../../../shared/ApolloClient";
import {makeStyles} from "@material-ui/core/styles";
import CreateProfileForm from "../components/CreateProfileForm";
import {ProfileOption, ProfileSource} from "../../../enrollment-types";
import {config} from "../../config/config";
import useSaveUserProfile from "../hooks/useSaveUserProfile";
import moment from "moment";
import { getCId } from "../../../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      flexDirection: "column-reverse",
      alignItems: "center",
      "& > *": {
        maxWidth: 320,
        width: "100%"
      }
    },
  })
);

const formName = "STEP_9";

type Step9Props = RouteComponentProps<any> & {quote: any, dispatch: any, onNextStep: () => void};

let promise = (age: number) => new Promise((resolve, reject) => {
  if (age >= 64) {
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://trkdis.com/l/con?cbiframe=1&oid=77108");
    ifrm.style.width = "1px";
    ifrm.style.height = "1px";
    document.body.appendChild(ifrm);
  }
  setTimeout(() => {
    resolve()
  }, 3000);
});

function Step9({quote, dispatch, ...props}: Step9Props) {
  const classes = useStyles();
  const [saveError, setSaveError] = React.useState();
  const [emailError, setEmailError] = React.useState();
  const [disabled, setDisabled] = React.useState(false);

  const [create, data] = useSaveUserProfile()

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = (values: any) => {
    if (values?.email) {
      setDisabled(true);
      create({
        variables: {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            birthDate: getStoredUser()?.birthDate,
            zip: getStoredUser()?.zip,
            countyName: getStoredUser()?.countyName,
            quoteId: getStoredUser()?.quoteId,
            medigapQuoteId: getStoredUser()?.medigapQuoteId,
            source: ProfileSource.UserFlowV1_5,
            cId: getCId(),
            options: [ProfileOption.RegisterUser],
            insuranceType: quote?.QUIZ_3?.medicarePlanType?.value as string
          }
        }
      }).then((res) => {
        if ((window as any).track) {
          (window as any).track('profile-saved', {'profile_id': res.data?.saveUserProfile.profileId, source: ProfileSource.UserFlowV1_5});
        } else {
          console.warn('No tag manager on the page')
        }
        setStoredUser({...getStoredUser(), firstName: values.firstName, lastName: values.lastName, email: values.email, phoneNumber: values.phoneNumber, profileId: res.data?.saveUserProfile.profileId})
        return res.data?.saveUserProfile?.userToken
      }).then(userToken => {
        return new Promise((resolve, reject) =>  {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', `${process.env.REACT_APP_KEYCLOAK_SERVER}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/set-cookie-by-token`, true);
          xhr.setRequestHeader('authorization', 'Bearer ' + userToken.accessToken);
          xhr.withCredentials = true;
          xhr.onreadystatechange = function () {
            if(xhr.readyState === XMLHttpRequest.DONE) {
              const status = xhr.status;
              if (status === 0 || (status >= 200 && status < 400)) {
                // The request has been completed successfully
                resolve()
              } else {
                // Oh no! There has been an error with the request!
                reject(xhr.responseText)
              }
            }
          };
          xhr.send();
        })

      })
          .then(() => promise(Math.floor(moment.duration(moment().diff(moment(getStoredUser()?.birthDate))).asYears()))).then(props.onNextStep).catch(error => {
        if (error?.graphQLErrors) {
          switch (error.graphQLErrors[0]?.extensions?.type) {
            case "INVALID_EMAIL":
            case GraphQLErrorType.ALREADY_EXISTS:
              setEmailError(<>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
              }} style={{color: "red"}}>sign in</a></>);
              break;
            case GraphQLErrorType.NO_CONNECTION:
              setSaveError("Service is not available");
              break;
            default:
              setSaveError("Unknown error")
          }
        } else if (error?.message) {
          setSaveError(error?.message)
        }
      }).finally(() => {
        setDisabled(false);
      })
    }
  };

  return <>
    <Box display={"flex"} justifyContent={"center"} flex={1}>
      <Box>
        <Typography className={'red fs-14 mt-16'} align={'center'}>{saveError}</Typography>
        <div className={"h-16"}/>
        <CreateProfileForm onSubmit={onSubmit}
                           emailError={emailError}
                           disabled={disabled}
                           buttonWrapperClassName={classes.buttonWrapper}
                           submitButtonLabel={'Continue'}
                           backButtonLabel={"Back"}
                           onCancelClick={() => props.history.goBack()}/>
      </Box>
    </Box>
  </>
}

export default Step9;
