import React, {forwardRef} from "react";
import {createStyles, makeStyles, TextField, Theme, InputAdornment} from "@material-ui/core";
import {OutlinedTextFieldProps} from "@material-ui/core/TextField/TextField";
import clsx from "clsx";
import {colors} from "../AppTheme";
import {config} from "../../Quote/config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '30px',
      width: '100%'
    },
    inputLabel: {
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '10px',
      color: colors.text.primary,
      fontWeight: 500,
    },
  }),
);

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #CCD8DC!important',
      },
      '&:not(.Mui-error) .MuiOutlinedInput-input': {
        backgroundColor: 'white',
      },
      borderRadius: '5px',
      backgroundColor: 'white',
      '&.Mui-error': {
        backgroundColor: 'rgba(220, 16, 28, 0.05)',
      },
    },
    input: {
      padding: '18px 20px 14px!important',
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: 500,
      backgroundColor: 'white',
    },
    multiline: {
      padding: '11px 7px 10px',
    },
    inputMultiline: {
      padding: 0,
    },
    error: {
      backgroundColor: 'rgba(220, 16, 28, 0.05)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px!important',
      }
    },
    focused: {
      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
        backgroundColor: 'rgba(2, 144, 148, 0.05)'
      }
    }
  }),
);

const useStylesHelperText = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    contained: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginTop: 6,
      whiteSpace: 'nowrap',
    },
  }),
);

const FormTextInput = (props: Partial<OutlinedTextFieldProps & {inputLabelClass?: string}>, ref: any) => {
  const classes = useStyles();
  const {inputLabelClass, ...rest} = props;
  const inputClasses = useStylesInput();
  const helperTextClasses = useStylesHelperText();

  return (
    <div className={clsx(classes.root, props.className)}>
      {
        props.label && (
          <div className={clsx(classes.inputLabel, inputLabelClass)}>
            <span>
              {props.label}
            </span>
          </div>
        )
      }
      <TextField
        {...rest}
        fullWidth={typeof props.fullWidth === 'undefined' ? true : props.fullWidth}
        ref={ref}
        className={undefined}
        variant="outlined"
        label={undefined}
        inputProps={{
          ...props.inputProps,
          className: 'data-hj-allow'
        }}
        InputProps={{
          ...props.InputProps,
          classes: {
            ...inputClasses,
            ...props?.InputProps?.classes
          },
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          classes: {
            ...helperTextClasses,
            ...props?.FormHelperTextProps?.classes
          },
        }}
      />
    </div>
  );
}

export default forwardRef(FormTextInput);

export const TickAdornment = ({className}: {className?: string}) => <InputAdornment disablePointerEvents position="end"><img className={className} src={(config.imagesUrl as string) + '/img/tick.svg'} /></InputAdornment>;
