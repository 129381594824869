import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import AscendModal from "../../../shared/components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import {config} from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 500,
      '& span': {
        color: colors.custom.green.variant1,
      }
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 400,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

type FindPlanModalProps = {
  onClose: () => void,
  open: boolean,
  countyName?: string
}

export default function SearchingPlanModal(props: FindPlanModalProps) {
  const classes = useStyles();
  const [timeout, setTimeoutValue] = React.useState();

  React.useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  React.useEffect(() => {
    if (props.open) {
      setTimeoutValue(setTimeout(() => {
        clearTimeout(timeout)
        props.onClose()
      }, 3000))
    }
  }, [props.open])

  return <AscendModal
    width={584}
    open={props.open}
    disableBackdropClick
  >
    <>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>
        Congratulations! We’ve found over <span>10</span> Medicare plans in your area.
      </Typography>
      <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
        <img src={(config.imagesUrl as string) + '/img/congratulations.svg'} />
      </Box>

      <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>{props.countyName}</Typography>
    </>
  </AscendModal>
}

