import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import React from "react";
import {config} from "../../Quote/config/config";

const useMarkerStyles = makeStyles({
  numberWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: 3,
    cursor: (props: MarkerProps) => props.clickable ? 'pointer' : undefined,
  },
  number: {
    fontWeight: 700,
    fontSize: 11,
    background: 'white',
    height: 20,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: '4px 0px 0 0',
  }
});

type MarkerProps = {
  index: number,
  clickable?: boolean,
  lat?: number,
  lng?: number,
  active?: boolean
}

const Marker = (props: MarkerProps) => {
  const classes = useMarkerStyles(props);
  return <Box position={'relative'} width={'26px'} zIndex={props.active ? 1 : undefined}>
    {props.active ? <img src={(config.imagesUrl as string) + '/img/marker-active.svg'}/> : <img src={(config.imagesUrl as string) + '/img/marker.svg'}/>}
    <div className={classes.numberWrapper}><div className={classes.number}>{props.index}</div></div>
  </Box>
}

export default Marker;
