import React from 'react';
import {DrugOutput, FrequencyPeriod} from "../../../types";
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";

export default function useDrugsByRequestId(requestId?: string) {
  const [drugs, setDrugs] = React.useState<DrugOutput[]>([]);
  const [loaded, setLoaded] = React.useState(true);

  const [getRequest, {loading: quoteLoading, called, refetch}] = useQuestionnaireRequest(data => {
    if (data.preferredDrugs?.preferredDrugs) {
      setDrugs(data.preferredDrugs?.preferredDrugs)
    }
    setLoaded(true);
  })

  React.useEffect(() => {
    if (requestId) {
      setLoaded(false);
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {drugs, setDrugs, loading: quoteLoading, refetch, called, loaded};
}

export interface PreferredDrug {
  ndc?: string;
  productId?: string;
  quantity: number;
  frequency: string;
  dosage: string;
  productRxcui: string;
  dosageRxcui: string;
  purchaseFrequency: FrequencyPeriod,
}
