import React from "react";
import {Box, createStyles, IconButton, MenuItem, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {DrugInfoWithPackagesFlags} from "../../types";
import AscendModal from "./AscendModal";
import FormSelectInput from "./FormSelectInput";
import AscendButton from "./AscendButton";
import {config} from "../../Quote/config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 500,
    },
    drug: {
      color: colors.custom.green.variant2,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 22
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        color: colors.custom.green.variant2 + '!important',
        padding: '15px 20px 14px!important',
        fontSize: 22
      },
    }
  })
);

interface NoPackagesModalProps {
  open: boolean,
  info?: DrugInfoWithPackagesFlags,
  onClose: (switchTo?: {name: string, rxcui: string }) => void,
}

export default function NoPackagesModal(props: NoPackagesModalProps) {
  const classes = useStyles();

  const [brandedRxcui, setBrandedRxcui] = React.useState<string>();

  React.useEffect(() => {
    setBrandedRxcui((props?.info?.brandedDetails[0]?.rxcui as string) || undefined)
  }, [props.open]);

  if (!props.info) {
    return null;
  }

  const hasPackages = props.info.hasBrandedPackages || props.info.hasGenericPackages;

  const buttonText = hasPackages ? `Add ${props.info.isGeneric ? 'branded' : 'generic'} drug` : `Back to drug search`;

  const submit = () => {
    if (props.info?.isGeneric) {
      if (props.info.brandedDetails.length === 1) {
        props.onClose({rxcui: props.info?.brandedDetails[0].rxcui as string, name: props.info?.brandedDetails[0].name} )
      } else {
        const drug = props.info?.brandedDetails.find(d => d.rxcui === brandedRxcui);
        if (drug) {
          props.onClose({rxcui: drug.rxcui, name: drug.name})
        } else {
          props.onClose();
        }
      }
    } else {
      props.onClose({rxcui: props.info?.genericRxcui as string, name: props.info?.genericName as string})
    }
  }

  return <AscendModal
    width={590}
    open={props.open}
    onClose={() => props.onClose()}
    paperPadding={'25px 45px 40px'}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={(config.imagesUrl as string) + '/img/close.svg'} /></IconButton>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>{props.info.name}</Typography>
      <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>isn’t currently available under any Medicare Advantage plan offered by CoverRight.</Typography>
      <div className={'h-24'} />
      {hasPackages && <>
        {!props.info.isGeneric && <>
          <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>There is a generic version of this drug called</Typography>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.drug}>{props.info.genericName}</Typography>
        </>}
        {(!!props.info.isGeneric && props.info.brandedDetails.length === 1) && <>
          <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>There is a branded version of this drug called</Typography>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.drug}>{props.info.brandedDetails[0].name}</Typography>
        </>}
        {(!!props.info.isGeneric && props.info.brandedDetails.length > 1) && <>
          <Typography variant={'h3'} color={'textSecondary'} className={`${classes.subtitle} mb-10`}>You can instead select from the following<br/> branded drugs:</Typography>
          <FormSelectInput
            fullWidth
            value={brandedRxcui}
            placeholder={'Select drug'}
            className={classes.select}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setBrandedRxcui(event.target.value as string);
            }}
          >
            {
              props.info.brandedDetails.map(drug => <MenuItem key={drug.rxcui} value={drug.rxcui}>{drug.name}</MenuItem>)
            }
          </FormSelectInput>
        </>}
        <div className={'h-25'} />
        <Typography color={'textPrimary'} className={'bold fs-18 text-align-centered'}>Would you like to add the {props.info.isGeneric ? 'branded' : 'generic'} version to<br />your list instead?</Typography>
      </>}
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'10px'}>
        <AscendButton variant={'contained'}
                      onClick={() => {
                        if (!hasPackages) {
                          props.onClose();
                        } else {
                          submit()
                        }
                      }}>
          {buttonText}
        </AscendButton>
        <Box pt={'20px'}>
          <Typography variant={'h4'} className={`${classes.link} branded-drug-button`} onClick={() => props.onClose()}>Close and go back to drug search</Typography>
        </Box>
      </Box>
    </>
  </AscendModal>
}
