import {Button, withStyles} from "@material-ui/core";
import {colors} from "../AppTheme";

export default withStyles({
  root: {
    transition: 'all 0.3s',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(81, 224, 165, 1)',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: 'rgba(81, 224, 165, 1)',
      boxShadow: 'none',
    },
    '&:focus': {
    },
  },
  text: {
    '& .MuiButton-label': {
      color: colors.custom.green.variant1,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  contained: {
    backgroundColor: colors.custom.green.variant1,
    padding: '15px 50px 11px',
    boxShadow: 'none',
    borderRadius: 100,
  },
  containedSecondary: {
    backgroundColor: 'rgba(28, 67, 79, 0.1)',
    padding: '15px 50px 11px',
    borderRadius: 100,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(28, 67, 79, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(28, 67, 79, 0.1)',
    },
    '& .MuiButton-label': {
      fontFamily: "Sailec",
      color: colors.text.primary,
      fontWeight: 400,
    }
  },
  disabled: {},
  outlined: {
    backgroundColor: 'white',
    border: `1px solid ${colors.custom.green.variant1}`,
    padding: '15px 50px 11px',
    borderRadius: 100,
    '& .MuiButton-label': {
      color: colors.custom.green.variant1,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: 'white',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'none',
  },
})(Button);
