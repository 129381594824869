import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as Quote from './Quote/App';

const App = Quote.default;

(window as any).CoverRightWidget = {
  mount: (props: any) => {
    const el = document.getElementById('cover-right-widget');
    if (el) {
      ReactDOM.render(<App {...props} />, el);
    }
  },
  unmount: () => {
    const el = document.getElementById('cover-right-widget');
    if (el) {
      ReactDOM.unmountComponentAtNode(el as any);
    }
  }
} as any

// (window as any).CoverRightWidget.mount({type: 'MG'});

