import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {questionsRoutes} from "../hooks/useStepperState";
import {colors} from "../../../shared/AppTheme";
import {makeStyles} from "@material-ui/core/styles";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import useCommonStyles from "../../../shared/useCommonStyles";
import {ProviderOutput} from "../../../types";
import {getQuoteId} from "../../../shared/QuoteId";
import paths from "../../config/router-paths";
import {DoctorSelection} from "../../../shared/components/DoctorSelection";
import AscendInfo from "../../../shared/components/AscendInfo";
import AscendButton from "../../../shared/components/AscendButton";
import {withTitle} from "../../../shared/components/withTitle";
import { RouteComponentProps } from "react-router-dom";
import useDoctorsByRequestId from "../hooks/useDoctorsByRequestId";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";

const useStyles = makeStyles({
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 22,
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 20
  },
  addDoctorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px dashed #D6DFDA',
    background: '#F5F7F6',
    borderRadius: 8,
    width: '100%',
    paddingTop: 27,
    paddingBottom: 23,
    color: colors.custom.green.variant1,
    fontWeight: 700,
    fontSize: 16,
  }
});

const formName = 'STEP_4';

function Step4() {
  const classes = useStyles();
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [selectedDoctors, setSelectedDoctors] = React.useState<ProviderOutput[]>([]);
  const [showAutocomplete, setShowAutocomplete] = React.useState(true); // todo replace this in component

  const {doctors} = useDoctorsByRequestId(getQuoteId());

  React.useEffect(() => {
    if (doctors.length) {
      setSelectedDoctors(doctors);
      setShowAutocomplete(false);
    }
  }, [doctors])

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {
        preferredDoctors: selectedDoctors,
        stateKey: 'preferredDoctors',
        form: formName,
        preferredDoctorsFilled: true}
    })
    if ((window as any).track) {
      (window as any).track('First time MA - add doctor', {source: 'V1.5'}, true);
    }
    dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step5}})
  };

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'40px'}>
      <Box display={'flex'} flexDirection={'column'} flex={1} mt={'30px'}>
        {!!selectedDoctors.length && <Box mb={'23px'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Your selected doctors:</Typography>
        </Box>}
        <DoctorSelection selectedDoctors={selectedDoctors}
                         zip={state.STEP_2?.zip as string}
                         setSelectedDoctors={setSelectedDoctors}
                         showAutocomplete={showAutocomplete}
                         setShowAutocomplete={setShowAutocomplete}
        />
      </Box>
      {showAutocomplete && <Box mt={'-10px'}>
        <Typography variant={'h4'} color={'textSecondary'} className={classes.title}>Can’t find your doctor? Call us and we can help.</Typography>
      </Box>}
      <div className={'h-10'} />
      <AscendInfo open={true}
                  description={
                    <p style={{ marginTop: 0, marginBottom: 0, color: colors.text.primary}}><strong>Doctor networks change all the time.</strong> We update our information regularly, but changes sometimes happen faster than insurers can update us.  We will aim to make sure to double check your doctors are covered before we enroll you.</p>}/>
    </Box>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step3}})
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    disabled={!selectedDoctors.length}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        if ((window as any).track) {
          (window as any).track('First time MA - skip doctor', {source: 'V1.5'});
        }
        dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step5}})
      }}>Click here to skip this step</Typography>
    </Box>
  </>
}

export default withTitle(
  Step4,
  'Are there any doctors that you would like\ncovered by your new plan?',
  'Doctors don’t work with every plan. We’ll show you plans that include them.'
)
