import React from "react";
import {createStyles, makeStyles, Theme, useTheme, withStyles} from "@material-ui/core/styles";
import withErrorBoundary from "../../../shared/withErrorBoundary";
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../../Medigap/hooks/useStepperState";
import {IStoredUser, resetStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {config} from "../../config/config";
import {AppStateActionTypes, AppStateContext, IMedicareType} from "../../../shared/AppStateContext";



const Home = () => {
  const [{currentStep}, dispatch] = React.useContext(AppStateContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  React.useEffect(() => {
    localStorage.removeItem('quote')
    localStorage.removeItem('medigapQuote')
    resetStoredUser();
  }, []);

  const onSubmit = (planType: IMedicareType) => {
    localStorage.setItem(planType === 'MA' ? 'quote' : 'medigapQuote', JSON.stringify({
      QUIZ_3: {
        medicarePlanType: {
          questionText: 'Medicare plan type',
          answerText: planType,
          value: planType
        }
      }
    }))
    if (planType === 'MA') {
      dispatch({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step2, medicareType: planType}})
    } else {
      dispatch({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MGquestionnaire + '/' + questionsRoutes.step2, medicareType: planType}})
    }
  }

  return <div>
    <Typography color={'textPrimary'} align={'center'} className={'fs-26 mb-35 bold'}>What type of plan are you looking for?</Typography>
    <Box display={'flex'} justifyContent={'center'} flexDirection={phone ? 'column' : 'row'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <StyledButton label={'Medicare Supplement (Medigap)'} img={config.imagesUrl + '/img/Hand.svg'} onClick={() => onSubmit(IMedicareType.MG)}/>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className={!phone ? 'w-85' : 'mv-15'}>
        <Typography color={'textPrimary'} align={'center'} className={'fs-20 bold'}>OR</Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <StyledButton label={<>Medicare<br />Advantage</>} img={config.imagesUrl + '/img/Building.svg'} onClick={() => onSubmit(IMedicareType.MA)}/>
      </Box>
    </Box>
  </div>;
}

export default withErrorBoundary(Home);

const StyledButton = withStyles({
  wrapper: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    width: 145,
    minWidth: 145,
    height: 145,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
  }
})(({label, img, onClick, classes}: {label: string | JSX.Element, img: string, onClick: () => void, classes: any}) => {
  return <div className={classes.wrapper} onClick={onClick}>
    <Typography color={'textPrimary'} align={'center'} className={'fs-14 bold'}>{label}</Typography>
    <img src={img} width={60} />
  </div>
})
