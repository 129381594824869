import React, {Suspense} from "react";
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {client, setErrorHandler} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import Quote from "./MedicareAdvantage/Quote";
import Home from "./shared/components/Home";
import {SnackbarProvider} from "notistack";
import MedigapQuote from "./Medigap/MedigapQuote";
import AppTheme from "../shared/AppTheme";
import Preloader from "../shared/Preloader";
import {MuiThemeProvider} from "@material-ui/core";
import {
  AppStateActionTypes,
  AppStateContext,
  IMedicareType,
  withAppStateContextProvider
} from "../shared/AppStateContext";
import {questionsRoutes} from "./MedicareAdvantage/hooks/useStepperState";
import {getCId, getUId} from "../shared/utils";
import {getStoredUser} from "../shared/StoredUser";

if (window) {
  Object.assign(window, {
    c_id: getCId(),
    product: 'Widget flow',
    version: `2.6.15`
  })
}


function App(props: any) {
  const [{currentStep, medicareType}, dispatchState] = React.useContext(AppStateContext);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if ((window as any).hj) {
      (window as any).hj('identify', getCId(), {
        email: getStoredUser()?.email,
        c_id: getCId(),
        uid: getUId(),
      })
    } else {
      console.warn('No hotjar on the page')
    }
  }, []);

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  React.useEffect(() => {
      if (props?.type) {
        if (props.type === 'MA') {
          dispatchState({
            type: AppStateActionTypes.UPDATE,
            payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step2, hideBackButton: true, medicareType: IMedicareType.MA}

          });
        } else if (props.type === 'MG') {
          dispatchState({
            type: AppStateActionTypes.UPDATE,
            payload: {currentStep: paths.MGquestionnaire + '/' + questionsRoutes.step2, hideBackButton: true, medicareType: IMedicareType.MG}
          });
        }
      }
    setLoaded(true);
  }, [props]);

  if (!loaded) {
    return null;
  }

  return (
    <MuiThemeProvider theme={AppTheme}>
      <SnackbarProvider maxSnack={3} preventDuplicate={true} classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <Suspense fallback={<Preloader />}>
            <ApolloProvider client={client}>
              {currentStep === '' && <Home />}
              {currentStep.includes(paths.MAquestionnaire) && <Quote />}
              {currentStep.includes(paths.MGquestionnaire) && <MedigapQuote />}
            </ApolloProvider>
        </Suspense>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default withAppStateContextProvider(App);
