import React from 'react';
import {ProviderOutput} from "../../../types";
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";

export default function useDoctorsByRequestId(requestId?: string) {
  const [doctors, setDoctors] = React.useState<ProviderOutput[]>([]);
  const [loaded, setLoaded] = React.useState(true);

  const [getRequest, {loading, refetch, called}] = useQuestionnaireRequest(async data => {
    if (data.preferredDoctors?.preferredDoctors?.length) {
      setDoctors(data.preferredDoctors?.preferredDoctors)
    }
    setLoaded(true);
  })

  React.useEffect(() => {
    if (requestId) {
      setLoaded(false);
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {doctors, setDoctors, loading, refetch, loaded, called};
}
