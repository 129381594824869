export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: any;
  /** Long type */
  Long: any;
  /** A type representing a string identifier */
  UUID: any;
};

export type BrandedInfo = {
  __typename?: 'BrandedInfo';
  name: Scalars['String'];
  rxcui: Scalars['String'];
};

export type BrokenAddress = {
  __typename?: 'BrokenAddress';
  address: Scalars['String'];
  geocodingType: GeocodingType;
};

export type CoverageEntryOutput = {
  __typename?: 'CoverageEntryOutput';
  month: Scalars['Int'];
  stage: DrugCalculationStage;
};

export type Doctor = {
  __typename?: 'Doctor';
  businessPracticeLocationFirstLine?: Maybe<Scalars['String']>;
  businessPracticeLocationSecondLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  officialFirstName?: Maybe<Scalars['String']>;
  officialLastName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type DoctorCoverage = {
  __typename?: 'DoctorCoverage';
  coverage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  npi: Scalars['String'];
};

export type DrugCostMonthlyBreakdownOutput = {
  __typename?: 'DrugCostMonthlyBreakdownOutput';
  costs: Array<DrugCostOutput>;
  coverageEntry: Array<CoverageEntryOutput>;
  monthlyCosts: Array<MonthlyTotalCost>;
  totalClientCost: Scalars['String'];
};

export type DrugCostOutput = {
  __typename?: 'DrugCostOutput';
  cost: Scalars['String'];
  isGeneric?: Maybe<Scalars['Boolean']>;
  month: Scalars['Int'];
  name: Scalars['String'];
  packageName: Scalars['String'];
  rxcui: Scalars['String'];
  stage: DrugCalculationStage;
};

export type DrugCoverage = {
  __typename?: 'DrugCoverage';
  coverage: Scalars['Boolean'];
  name: Scalars['String'];
  packName: Scalars['String'];
  packRxcui: Scalars['String'];
  rxcui?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export type DrugInfoWithGeneric = {
  __typename?: 'DrugInfoWithGeneric';
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type DrugInfoWithPack = {
  __typename?: 'DrugInfoWithPack';
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type DrugInfoWithPackagesFlags = {
  __typename?: 'DrugInfoWithPackagesFlags';
  brandedDetails: Array<BrandedInfo>;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  hasBrandedPackages: Scalars['Boolean'];
  hasGenericPackages: Scalars['Boolean'];
  isGeneric: Scalars['Boolean'];
  name: Scalars['String'];
  rxcui: Scalars['String'];
};

export type DrugOutput = {
  __typename?: 'DrugOutput';
  frequency: CalcDrugFrequencyPeriod;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui: Scalars['String'];
  purchaseFrequency: FrequencyPeriod;
  quantity: Scalars['BigDecimal'];
  rxcui: Scalars['String'];
};

export type DrugPriceOutput = {
  __typename?: 'DrugPriceOutput';
  catastrophicPrice: Scalars['String'];
  coverageGapPrice: Scalars['String'];
  initialCoveragePrice: Scalars['String'];
  isAggregatedPrice: Scalars['Boolean'];
  isCoveredByPlan: Scalars['Boolean'];
  isGeneric: Scalars['Boolean'];
  isInsulinDrug: Scalars['Boolean'];
  name: Scalars['String'];
  packageDescription: Scalars['String'];
  price: Scalars['String'];
  quantityLimit?: Maybe<Scalars['String']>;
  quoteQuantity: Scalars['String'];
  rxcui: Scalars['String'];
  tier?: Maybe<Scalars['Int']>;
  unitPrice: Scalars['String'];
};

export type DrugProduct = {
  __typename?: 'DrugProduct';
  activeIngredUnit?: Maybe<Scalars['String']>;
  activeNumeratorStrength?: Maybe<Scalars['String']>;
  applicationNumber?: Maybe<Scalars['String']>;
  deadSchedule?: Maybe<Scalars['String']>;
  dosageFormName?: Maybe<Scalars['String']>;
  endMarketingDate?: Maybe<Scalars['String']>;
  generics: Array<DrugProduct>;
  id: Scalars['Long'];
  isGeneric: Scalars['Boolean'];
  labelName?: Maybe<Scalars['String']>;
  listingRecordCertifiedThrough?: Maybe<Scalars['String']>;
  marketingCategoryName?: Maybe<Scalars['String']>;
  ndcExcludeFlag?: Maybe<Scalars['String']>;
  nonProprietaryName?: Maybe<Scalars['String']>;
  pharmClasses?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productNdc?: Maybe<Scalars['String']>;
  productTypeName?: Maybe<Scalars['String']>;
  proprietaryName?: Maybe<Scalars['String']>;
  proprietaryNameSuffix?: Maybe<Scalars['String']>;
  routeName?: Maybe<Scalars['String']>;
  startMarketingDate?: Maybe<Scalars['String']>;
  substanceName?: Maybe<Scalars['String']>;
};

export type DrugTier = {
  __typename?: 'DrugTier';
  title: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type DrugTierCoverageOutput = {
  __typename?: 'DrugTierCoverageOutput';
  coverage: Scalars['String'];
  tier: DrugTierOutput;
  tierName: Scalars['String'];
};

export type DrugTiersOutput = {
  __typename?: 'DrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
};

export type DrugsDetails = {
  __typename?: 'DrugsDetails';
  catastrophicCoverage: Array<PharmacyTier>;
  catastrophicCoverageDescription: Scalars['String'];
  coverageGap: Array<PharmacyTier>;
  coverageGapDescription: Scalars['String'];
  initialCoverage: Array<PharmacyTier>;
  initialCoverageDescription: Scalars['String'];
  initialCoverageLimit: Scalars['String'];
  mrxAltDedAmount: Scalars['String'];
  mrxAltDedAmountShort: Scalars['String'];
  mrxAltNoDedTier: Scalars['String'];
};

export type ExternalId = {
  __typename?: 'ExternalId';
  type: Scalars['String'];
  value: Scalars['String'];
};

export type ExtraBenefits = {
  __typename?: 'ExtraBenefits';
  dental: Scalars['Boolean'];
  drugCoverage: Scalars['Boolean'];
  fitness: Scalars['Boolean'];
  hearing: Scalars['Boolean'];
  insulinSavings: Scalars['Boolean'];
  overTheCounter: Scalars['Boolean'];
  telehealth: Scalars['Boolean'];
  transportation: Scalars['Boolean'];
  vision: Scalars['Boolean'];
  worldwideEmergency: Scalars['Boolean'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  count: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type FiltersDto = {
  __typename?: 'FiltersDto';
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  extraBenefits: Array<ExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
};

export type HospitalityDetails = {
  __typename?: 'HospitalityDetails';
  ambulanceServices: Array<Scalars['String']>;
  emergencyRoom: Scalars['String'];
  urgentServices: Scalars['String'];
};

export type LocationOutput = {
  __typename?: 'LocationOutput';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type MedicareQuoteAuditLogOutput = {
  __typename?: 'MedicareQuoteAuditLogOutput';
  cId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  form?: Maybe<FormName>;
  id: Scalars['UUID'];
};

export type MedicareQuoteOutput = {
  __typename?: 'MedicareQuoteOutput';
  abCase?: Maybe<Scalars['String']>;
  agentOffer?: Maybe<Scalars['Boolean']>;
  cId?: Maybe<Scalars['String']>;
  chronicIllness?: Maybe<Scalars['Boolean']>;
  county?: Maybe<Scalars['String']>;
  coverage?: Maybe<Array<Scalars['String']>>;
  coverageFilled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Instant']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctorVisitsNumber?: Maybe<Scalars['Int']>;
  doctorsWithinNetwork?: Maybe<Scalars['Boolean']>;
  drugDeliveryType?: Maybe<DrugDeliveryTypeDto>;
  employed?: Maybe<Scalars['Boolean']>;
  enrolled?: Maybe<Array<Scalars['String']>>;
  enrolledFilled?: Maybe<Scalars['Boolean']>;
  favorites: Array<Scalars['String']>;
  filters?: Maybe<FiltersDto>;
  governmentAssistant?: Maybe<Array<Scalars['String']>>;
  governmentAssistantFilled?: Maybe<Scalars['Boolean']>;
  hasMore20?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  medicarePlanType?: Maybe<Scalars['String']>;
  pin: Scalars['String'];
  preferredDoctors: Array<ProviderOutput>;
  preferredDoctorsFilled?: Maybe<Scalars['Boolean']>;
  preferredDrugs: Array<DrugOutput>;
  preferredDrugsFilled?: Maybe<Scalars['Boolean']>;
  preferredPharmacies: Array<PharmacyLocationDistanceOutput>;
  preferredPharmaciesFilled?: Maybe<Scalars['Boolean']>;
  prepayCoverage?: Maybe<Scalars['Boolean']>;
  receivedBenefits?: Maybe<Scalars['Boolean']>;
  receivesLIS?: Maybe<Scalars['Boolean']>;
  receivesMedicalSupport?: Maybe<Scalars['Boolean']>;
  restrictProviderNetwork?: Maybe<Scalars['Boolean']>;
  specialistVisitsNumber?: Maybe<Scalars['Int']>;
  spouseEmployed?: Maybe<Scalars['Boolean']>;
  spouseHasMore20?: Maybe<Scalars['Boolean']>;
  status?: Maybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['UUID']>;
  work8years?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type MonthlyTotalCost = {
  __typename?: 'MonthlyTotalCost';
  amount: Scalars['BigDecimal'];
  amountString: Scalars['String'];
  month: Scalars['Int'];
  stage: DrugCalculationStage;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedicareQuoteForClient: Scalars['UUID'];
  createMedicareQuoteFromMedigapQuote: Scalars['UUID'];
  deleteVideo: Scalars['String'];
  deleteVideoCategory: Scalars['String'];
  generatePlanEmail?: Maybe<Scalars['String']>;
  saveCategoryOrder: Scalars['String'];
  saveMedicareQuote: Scalars['UUID'];
  saveVideo?: Maybe<Scalars['UUID']>;
  saveVideoCategory?: Maybe<Scalars['UUID']>;
  saveVideoOrder: Scalars['String'];
  sendMedicareTypeCompareEmail: Scalars['String'];
  sendRequestFormReceivedEmail: Scalars['String'];
  warmUpPharmaciesGeocodingCache?: Maybe<Scalars['String']>;
};


export type MutationCreateMedicareQuoteForClientArgs = {
  agentOffer?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['UUID'];
  data: MedicareQuoteInput;
};


export type MutationCreateMedicareQuoteFromMedigapQuoteArgs = {
  medigapQuoteId: Scalars['UUID'];
};


export type MutationDeleteVideoArgs = {
  videoId: Scalars['UUID'];
};


export type MutationDeleteVideoCategoryArgs = {
  categoryId: Scalars['UUID'];
};


export type MutationGeneratePlanEmailArgs = {
  input: PlansCompareInput;
};


export type MutationSaveCategoryOrderArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationSaveMedicareQuoteArgs = {
  data: MedicareQuoteInput;
};


export type MutationSaveVideoArgs = {
  video: VideoInput;
};


export type MutationSaveVideoCategoryArgs = {
  category: VideoCategoryInput;
};


export type MutationSaveVideoOrderArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationSendMedicareTypeCompareEmailArgs = {
  email: Scalars['String'];
  input: MedicareTypeCompareInput;
};


export type MutationSendRequestFormReceivedEmailArgs = {
  bidId: Scalars['String'];
  email: Scalars['String'];
  filterInput: PlansFilterInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  quoteId: Scalars['UUID'];
};


export type MutationWarmUpPharmaciesGeocodingCacheArgs = {
  stateCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Network = {
  __typename?: 'Network';
  name: Scalars['String'];
  tier?: Maybe<Scalars['String']>;
};

export type NetworkInfoOutput = {
  __typename?: 'NetworkInfoOutput';
  carrierName: Scalars['String'];
  name: Scalars['String'];
};

export type OtherDefinedSupplementalBenefits = {
  __typename?: 'OtherDefinedSupplementalBenefits';
  counselingServices: Array<Scalars['String']>;
  enhancedDiseaseManagement: Array<Scalars['String']>;
  fitnessBenefit: Array<Scalars['String']>;
  healthEducation: Array<Scalars['String']>;
  homeAndBathroomSafety: Array<Scalars['String']>;
  inHomeSafetyAssessment: Array<Scalars['String']>;
  medicalNutritionTherapy: Array<Scalars['String']>;
  nutritionalDietaryBenefit: Array<Scalars['String']>;
  personalEmergencyResponseSystem: Array<Scalars['String']>;
  postDischargeInHome: Array<Scalars['String']>;
  remoteAccessTechnologies: Array<Scalars['String']>;
  smokingAndTobaccoCessationCounseling: Array<Scalars['String']>;
  telemonitoringServices: Array<Scalars['String']>;
};

export type PackInfoOuput = {
  __typename?: 'PackInfoOuput';
  name?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type PageableMedicareQuoteAuditLogOutput = {
  __typename?: 'PageableMedicareQuoteAuditLogOutput';
  data: Array<MedicareQuoteAuditLogOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableMedicareQuoteOutput = {
  __typename?: 'PageableMedicareQuoteOutput';
  data: Array<MedicareQuoteOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageablePharmacyLocationOutput = {
  __typename?: 'PageablePharmacyLocationOutput';
  data: Array<PharmacyLocationDistanceOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageablePlanOutput = {
  __typename?: 'PageablePlanOutput';
  data: Array<Plan>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PharmacyLocationDistanceOutput = {
  __typename?: 'PharmacyLocationDistanceOutput';
  address: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  npi: Scalars['String'];
  zip: Scalars['String'];
};

export type PharmacyTier = {
  __typename?: 'PharmacyTier';
  items: Array<DrugTier>;
  title?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  SNPType?: Maybe<SnpType>;
  alternativeMedicine: Array<Scalars['String']>;
  annualPhysicalExam: Array<Scalars['String']>;
  bidId: Scalars['String'];
  calculableDoctorsCost: Scalars['Boolean'];
  chiropractic: Array<Scalars['String']>;
  dental: Array<Scalars['String']>;
  doctorsCost?: Maybe<Scalars['String']>;
  doctorsCoverage: Array<DoctorCoverage>;
  drugCostMonthlyBreakdown?: Maybe<DrugCostMonthlyBreakdownOutput>;
  drugDetails?: Maybe<DrugsDetails>;
  drugPrices: Array<DrugPriceOutput>;
  drugTiers?: Maybe<DrugTiersOutput>;
  drugsCost?: Maybe<Scalars['String']>;
  drugsCoverage: Array<DrugCoverage>;
  extraBenefits: ExtraBenefits;
  firstWeekOfStay?: Maybe<Scalars['String']>;
  hearing: Array<Scalars['String']>;
  homeHealth: Array<Scalars['String']>;
  hospitalBenefits: Array<Scalars['String']>;
  hospitalityDetails: HospitalityDetails;
  inNetworkDeductableAmount?: Maybe<Scalars['String']>;
  maxEnrollmentAmount?: Maybe<Scalars['String']>;
  meal: Array<Scalars['String']>;
  mentalHealth: Array<Scalars['String']>;
  monthlyCost?: Maybe<Scalars['String']>;
  monthlyPremium?: Maybe<Scalars['String']>;
  oTC: Array<Scalars['String']>;
  optionalSupplementalBenefits: Scalars['Boolean'];
  optionalSupplementalBenefitsItems: Array<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  otherDefinedSupplemental: OtherDefinedSupplementalBenefits;
  outOfPocketAmount?: Maybe<Scalars['String']>;
  outOfPocketMaximum: Array<Scalars['String']>;
  outpatientHospitality: Array<Scalars['String']>;
  outpatientRehabilitation: Array<Scalars['String']>;
  outpatientSurgery: Array<Scalars['String']>;
  parentOrgName?: Maybe<Scalars['String']>;
  partBAmount?: Maybe<Scalars['String']>;
  partBGiveBack?: Maybe<Scalars['Boolean']>;
  physicianSpecialist: Array<Scalars['String']>;
  physicianSpecialistShort: Array<Scalars['String']>;
  planFiles?: Maybe<PlanFilesOutput>;
  planName?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
  planTypeCode?: Maybe<Scalars['String']>;
  preventiveCare: Array<Scalars['String']>;
  primaryCarePhysician: Array<Scalars['String']>;
  primaryCarePhysicianInNetwork?: Maybe<Scalars['String']>;
  primaryCarePhysicianOutOfNetwork?: Maybe<Scalars['String']>;
  primaryCarePhysicianShort?: Maybe<Scalars['String']>;
  quoteDrugsTiers?: Maybe<QuoteDrugTiersOutput>;
  rating?: Maybe<Scalars['Float']>;
  ratingNote?: Maybe<Scalars['String']>;
  skilledNursing: Array<Scalars['String']>;
  telehealth: Array<Scalars['String']>;
  transportation: Array<Scalars['String']>;
  vision: Array<Scalars['String']>;
  zip: Scalars['String'];
};


export type PlanDrugTiersArgs = {
  deliveryMethod: DrugDeliveryMethod;
  supplyDuration: DrugSupplyDuration;
};


export type PlanQuoteDrugsTiersArgs = {
  supplyDuration: DrugSupplyDuration;
};

export type PlanFilesOutput = {
  __typename?: 'PlanFilesOutput';
  bidId: Scalars['String'];
  evidenceOfCoverageDocUrl?: Maybe<Scalars['String']>;
  nonDiscriminationNoticeDocUrl?: Maybe<Scalars['String']>;
  preEnrollmentChecklistDocUrl?: Maybe<Scalars['String']>;
  starRatingDescDocUrl?: Maybe<Scalars['String']>;
  summaryOfBenefitsUrl?: Maybe<Scalars['String']>;
};

export type PlansSummaryOutput = {
  __typename?: 'PlansSummaryOutput';
  drugsCoveredPercent: Scalars['Int'];
  hmoCount: Scalars['Int'];
  ppoCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  zeroPremiumPercent: Scalars['Int'];
};

export type ProviderAddressOutput = {
  __typename?: 'ProviderAddressOutput';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  id: Scalars['String'];
  isPcp: Scalars['Boolean'];
  specialty: Scalars['String'];
};

export type ProviderOutput = {
  __typename?: 'ProviderOutput';
  address: ProviderAddressOutput;
  addressesNumber: Scalars['Int'];
  name: Scalars['String'];
  npi: Scalars['String'];
  pcpDetails: Array<ProviderPcpInfoOutput>;
};

export type ProviderPcpInfoOutput = {
  __typename?: 'ProviderPcpInfoOutput';
  id: Scalars['UUID'];
  networkDetails: Array<NetworkInfoOutput>;
  pcpId: Scalars['String'];
};

export type ProviderPlanOutput = {
  __typename?: 'ProviderPlanOutput';
  carrierName?: Maybe<Scalars['String']>;
  externalIds: Array<ExternalId>;
  id: Scalars['String'];
  lineOfCoverage: Scalars['String'];
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  networks: Array<Network>;
};

export type Query = {
  __typename?: 'Query';
  SNPTypes: Array<FilterItem>;
  alternativeMedicineByBidId: Array<Scalars['String']>;
  annualPhysicalExam: Array<Scalars['String']>;
  companies: Array<FilterItem>;
  drugPackagesInfo?: Maybe<DrugInfoWithPackagesFlags>;
  drugs: Array<DrugInfoWithGeneric>;
  drugsWithPackage: Array<DrugInfoWithPack>;
  extraBenefits: Array<FilterItem>;
  findBrokenAddresses: Array<BrokenAddress>;
  findChiropracticByBidId: Array<Scalars['String']>;
  findDentalBenefitByBidId: Array<Scalars['String']>;
  findDiscountCouponLinks: Array<Scalars['String']>;
  findDoctorsByNpi?: Maybe<Doctor>;
  findDrugByProductId?: Maybe<DrugProduct>;
  findDrugsDetailsByBidId?: Maybe<DrugsDetails>;
  findHearingBenefitByBidId: Array<Scalars['String']>;
  findHospitalBenefits: Array<Scalars['String']>;
  findMealBenefitsByBidId: Array<Scalars['String']>;
  findOTCBenefitByBidId: Array<Scalars['String']>;
  findPharmaciesByAddress: PageablePharmacyLocationOutput;
  findPharmaciesByLocation: PageablePharmacyLocationOutput;
  findPharmaciesWithinRectangle: PageablePharmacyLocationOutput;
  findPharmacyByNpi?: Maybe<PharmacyLocationDistanceOutput>;
  findProviderAddressesByNpi: Array<ProviderAddressOutput>;
  findProviderByNpi?: Maybe<ProviderOutput>;
  findProvidersByAddressInputs: Array<ProviderOutput>;
  findProvidersByFilter: Array<ProviderOutput>;
  findProvidersPlansByFilter: Array<ProviderPlanOutput>;
  findRequestAuditLogById?: Maybe<MedicareQuoteAuditLogOutput>;
  findRequestAuditLogs: PageableMedicareQuoteAuditLogOutput;
  findTransportationBenefitByBidId: Array<Scalars['String']>;
  findVisionBenefitByBidId: Array<Scalars['String']>;
  findZipLocation?: Maybe<LocationOutput>;
  generateEhealthPlanLink: Scalars['String'];
  getMyQuoteId?: Maybe<Scalars['UUID']>;
  homeHealth: Array<Scalars['String']>;
  hospitalityDetails: HospitalityDetails;
  maxCostRanges: Array<FilterItem>;
  maxOutOfPocketRanges: Array<FilterItem>;
  medicareQuote?: Maybe<MedicareQuoteOutput>;
  medicareQuoteByPin?: Maybe<MedicareQuoteOutput>;
  medicareQuotes: PageableMedicareQuoteOutput;
  mentalHealthByBidId: Array<Scalars['String']>;
  otherDefinedSupplementalBenefits: OtherDefinedSupplementalBenefits;
  outpatientHospitality: Array<Scalars['String']>;
  outpatientRehabilitation: Array<Scalars['String']>;
  outpatientSurgery: Array<Scalars['String']>;
  packageDescriptionByNdc?: Maybe<Scalars['String']>;
  packageDescriptionByRxcui?: Maybe<Scalars['String']>;
  packages: Array<PackInfoOuput>;
  partBPremiumReductionCount: Scalars['Int'];
  plan?: Maybe<Plan>;
  planTypes: Array<FilterItem>;
  plans: PageablePlanOutput;
  plansSummary: PlansSummaryOutput;
  skilledNursing: Array<Scalars['String']>;
  video: Video;
  videoCategories: Array<VideoCategoryWithVideos>;
  videoContent?: Maybe<VideoCategoryWithVideos>;
};


export type QuerySnpTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryAlternativeMedicineByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryAnnualPhysicalExamArgs = {
  bidId: Scalars['String'];
};


export type QueryCompaniesArgs = {
  filter: PlansFilterInput;
};


export type QueryDrugPackagesInfoArgs = {
  rxcui: Scalars['String'];
};


export type QueryDrugsArgs = {
  name: Scalars['String'];
};


export type QueryDrugsWithPackageArgs = {
  pairs: Array<ProductPackagePairInput>;
};


export type QueryExtraBenefitsArgs = {
  filter: PlansFilterInput;
};


export type QueryFindBrokenAddressesArgs = {
  addressSearchTerm?: Maybe<Scalars['String']>;
};


export type QueryFindChiropracticByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindDentalBenefitByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindDiscountCouponLinksArgs = {
  rxcui: Scalars['String'];
};


export type QueryFindDoctorsByNpiArgs = {
  npi: Scalars['String'];
};


export type QueryFindDrugByProductIdArgs = {
  productId: Scalars['String'];
};


export type QueryFindDrugsDetailsByBidIdArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  zip: Scalars['String'];
};


export type QueryFindHearingBenefitByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindHospitalBenefitsArgs = {
  bidId: Scalars['String'];
};


export type QueryFindMealBenefitsByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindOtcBenefitByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindPharmaciesByAddressArgs = {
  filterInput: PharmacyAddressFilterInput;
  page: PageInput;
};


export type QueryFindPharmaciesByLocationArgs = {
  filterInput: PharmacyLocationFilterInput;
  page: PageInput;
};


export type QueryFindPharmaciesWithinRectangleArgs = {
  filterInput: PharmacyWithinRectangleFilterInput;
  page: PageInput;
};


export type QueryFindPharmacyByNpiArgs = {
  lengthUnit?: Maybe<LengthUnit>;
  npi: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};


export type QueryFindProviderAddressesByNpiArgs = {
  npi: Scalars['String'];
};


export type QueryFindProviderByNpiArgs = {
  addressId: Scalars['String'];
  npi: Scalars['String'];
};


export type QueryFindProvidersByAddressInputsArgs = {
  addressInputs: Array<ProviderAddressInput>;
};


export type QueryFindProvidersByFilterArgs = {
  filterInput: ProviderFilterInput;
};


export type QueryFindProvidersPlansByFilterArgs = {
  filterInput: ProviderPlansFilterInput;
};


export type QueryFindRequestAuditLogByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryFindRequestAuditLogsArgs = {
  filterInput?: Maybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryFindTransportationBenefitByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindVisionBenefitByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryFindZipLocationArgs = {
  zip: Scalars['String'];
};


export type QueryGenerateEhealthPlanLinkArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  zip: Scalars['String'];
};


export type QueryHomeHealthArgs = {
  bidId: Scalars['String'];
};


export type QueryHospitalityDetailsArgs = {
  bidId: Scalars['String'];
};


export type QueryMaxCostRangesArgs = {
  filter: PlansFilterInput;
};


export type QueryMaxOutOfPocketRangesArgs = {
  filter: PlansFilterInput;
};


export type QueryMedicareQuoteArgs = {
  id: Scalars['UUID'];
};


export type QueryMedicareQuoteByPinArgs = {
  pin: Scalars['String'];
};


export type QueryMedicareQuotesArgs = {
  filterInput?: Maybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryMentalHealthByBidIdArgs = {
  bidId: Scalars['String'];
};


export type QueryOtherDefinedSupplementalBenefitsArgs = {
  bidId: Scalars['String'];
};


export type QueryOutpatientHospitalityArgs = {
  bidId: Scalars['String'];
};


export type QueryOutpatientRehabilitationArgs = {
  bidId: Scalars['String'];
};


export type QueryOutpatientSurgeryArgs = {
  bidId: Scalars['String'];
};


export type QueryPackageDescriptionByNdcArgs = {
  ndc: Scalars['String'];
};


export type QueryPackageDescriptionByRxcuiArgs = {
  rxcui: Scalars['String'];
};


export type QueryPackagesArgs = {
  rxcui: Scalars['String'];
};


export type QueryPartBPremiumReductionCountArgs = {
  filter: PlansFilterInput;
};


export type QueryPlanArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  quoteId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
};


export type QueryPlanTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryPlansArgs = {
  filter: PlansFilterInput;
  page: PageInput;
  quoteId?: Maybe<Scalars['UUID']>;
  sort: Array<PlansSortInput>;
};


export type QueryPlansSummaryArgs = {
  countyName: Scalars['String'];
  extraBenefits?: Maybe<Array<ExtraBenefit>>;
  quoteId?: Maybe<Scalars['UUID']>;
  showAllPlans?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};


export type QuerySkilledNursingArgs = {
  bidId: Scalars['String'];
};


export type QueryVideoArgs = {
  videoId: Scalars['UUID'];
};


export type QueryVideoContentArgs = {
  categoryId: Scalars['UUID'];
};

export type QuoteDrugTiersOutput = {
  __typename?: 'QuoteDrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  deliveryType: DrugDeliveryMethod;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  npi: Scalars['String'];
  pharmacyName: Scalars['String'];
};

export type Video = {
  __typename?: 'Video';
  categoryId?: Maybe<Scalars['UUID']>;
  description: Scalars['String'];
  duration: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type VideoCategoryWithVideos = {
  __typename?: 'VideoCategoryWithVideos';
  id?: Maybe<Scalars['UUID']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  videos: Array<Video>;
};

export enum CalcDrugFrequencyPeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum DrugCalculationStage {
  Catastrophic = 'CATASTROPHIC',
  CoverageGap = 'COVERAGE_GAP',
  Deductible = 'DEDUCTIBLE',
  InitialCoverage = 'INITIAL_COVERAGE',
  NotCovered = 'NOT_COVERED'
}

export enum DrugDeliveryMethod {
  PreferredMail = 'PREFERRED_MAIL',
  PreferredRetail = 'PREFERRED_RETAIL',
  StandardMail = 'STANDARD_MAIL',
  StandardRetail = 'STANDARD_RETAIL'
}

export enum DrugDeliveryTypeDto {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export enum DrugSupplyDuration {
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  TwoMonths = 'TWO_MONTHS'
}

export enum DrugTierOutput {
  Generic = 'GENERIC',
  NonPreferredBrand = 'NON_PREFERRED_BRAND',
  PreferredBrand = 'PREFERRED_BRAND',
  PreferredGeneric = 'PREFERRED_GENERIC',
  SelectCareDrugs = 'SELECT_CARE_DRUGS',
  Specialty = 'SPECIALTY'
}

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum Field {
  Deductible = 'DEDUCTIBLE',
  MaxOutOfPocket = 'MAX_OUT_OF_POCKET',
  MonthlyCost = 'MONTHLY_COST',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Rating = 'RATING'
}

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum FrequencyPeriod {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export enum GeocodingType {
  Google = 'GOOGLE'
}

export enum LengthUnit {
  Kilometer = 'KILOMETER',
  Mile = 'MILE'
}

export enum Rating {
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export enum SnpType {
  CSnp = 'C_SNP',
  DSnp = 'D_SNP',
  ISnp = 'I_SNP'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortType {
  Distance = 'DISTANCE',
  Name = 'NAME'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type DoctorInput = {
  addressId?: Maybe<Scalars['String']>;
  npi: Scalars['String'];
};

export type DrugInput = {
  dosageRxcui: Scalars['String'];
  frequency: CalcDrugFrequencyPeriod;
  productRxcui: Scalars['String'];
  purchaseFrequency: FrequencyPeriod;
  quantity: Scalars['BigDecimal'];
};

export type FiltersDtoInput = {
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  extraBenefits: Array<ExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
};

export type MedicareQuoteFilterInput = {
  endDate?: Maybe<Scalars['Instant']>;
  startDate?: Maybe<Scalars['Instant']>;
  statuses?: Maybe<Array<RequestStatusDto>>;
};

export type MedicareQuoteInput = {
  abCase?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  chronicIllness?: Maybe<Scalars['Boolean']>;
  county?: Maybe<Scalars['String']>;
  coverage?: Maybe<Array<Scalars['String']>>;
  coverageFilled?: Maybe<Scalars['Boolean']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctorVisitsNumber?: Maybe<Scalars['Int']>;
  doctorsWithinNetwork?: Maybe<Scalars['Boolean']>;
  drugDeliveryType?: Maybe<DrugDeliveryTypeDto>;
  employed?: Maybe<Scalars['Boolean']>;
  enrolled?: Maybe<Array<Scalars['String']>>;
  enrolledFilled?: Maybe<Scalars['Boolean']>;
  favorites?: Maybe<Array<Scalars['String']>>;
  filters?: Maybe<FiltersDtoInput>;
  form?: Maybe<FormName>;
  governmentAssistant?: Maybe<Array<Scalars['String']>>;
  governmentAssistantFilled?: Maybe<Scalars['Boolean']>;
  hasMore20?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  medicarePlanType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredDoctors?: Maybe<Array<DoctorInput>>;
  preferredDoctorsFilled?: Maybe<Scalars['Boolean']>;
  preferredDrugs?: Maybe<Array<DrugInput>>;
  preferredDrugsFilled?: Maybe<Scalars['Boolean']>;
  preferredPharmacies?: Maybe<Array<Scalars['String']>>;
  preferredPharmaciesFilled?: Maybe<Scalars['Boolean']>;
  prepayCoverage?: Maybe<Scalars['Boolean']>;
  receivedBenefits?: Maybe<Scalars['Boolean']>;
  receivesLIS?: Maybe<Scalars['Boolean']>;
  receivesMedicalSupport?: Maybe<Scalars['Boolean']>;
  restrictProviderNetwork?: Maybe<Scalars['Boolean']>;
  specialistVisitsNumber?: Maybe<Scalars['Int']>;
  spouseEmployed?: Maybe<Scalars['Boolean']>;
  spouseHasMore20?: Maybe<Scalars['Boolean']>;
  status?: Maybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: Maybe<Scalars['Boolean']>;
  work8years?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

export type MedicareTypeCompareInput = {
  certainDoctors?: Maybe<Scalars['Boolean']>;
  drugsCoverage?: Maybe<Scalars['Boolean']>;
  extraBenefits?: Maybe<Scalars['Boolean']>;
  monthlyBudget?: Maybe<Scalars['Boolean']>;
  prepay?: Maybe<Scalars['Boolean']>;
  receiveMedicaid?: Maybe<Scalars['Boolean']>;
};

export type PageInput = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type PharmacyAddressFilterInput = {
  address: Scalars['String'];
  lengthUnit?: Maybe<LengthUnit>;
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortType>;
};

export type PharmacyLocationFilterInput = {
  latitude: Scalars['Float'];
  lengthUnit?: Maybe<LengthUnit>;
  longitude: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortType>;
};

export type PharmacyWithinRectangleFilterInput = {
  name?: Maybe<Scalars['String']>;
  rectangle: RectangleInput;
};

export type PlansCompareInput = {
  bidIds: Array<Scalars['String']>;
  county: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  onlyPreview?: Maybe<Scalars['Boolean']>;
  quoteId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
};

export type PlansFilterInput = {
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  countyName: Scalars['String'];
  extraBenefits?: Maybe<Array<ExtraBenefit>>;
  isMA?: Maybe<Scalars['Boolean']>;
  isMD?: Maybe<Scalars['Boolean']>;
  isMS?: Maybe<Scalars['Boolean']>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  maxPremium?: Maybe<Scalars['BigDecimal']>;
  onlyFavorite?: Maybe<Scalars['Boolean']>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
  showAllPlans?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type PlansSortInput = {
  direction: Sort;
  field: Field;
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ProductPackagePairInput = {
  packageRxcui: Scalars['String'];
  productRxcui: Scalars['String'];
};

export type ProviderAddressInput = {
  addressId: Scalars['String'];
  npi: Scalars['String'];
};

export type ProviderFilterInput = {
  radius?: Maybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
  zipCode: Scalars['String'];
};

export type ProviderPlansFilterInput = {
  lineOfCoverage?: Maybe<Scalars['String']>;
  npi: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};

export type RectangleInput = {
  diagonalPoint: PointInput;
  firstPoint: PointInput;
};

export type VideoCategoryInput = {
  id?: Maybe<Scalars['UUID']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type VideoInput = {
  categoryId?: Maybe<Scalars['UUID']>;
  description: Scalars['String'];
  duration: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  url: Scalars['String'];
};
