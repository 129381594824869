import React from "react";
import {Box} from "@material-ui/core";
import useStepperState from "./hooks/useStepperState";
import {RouteComponentProps} from "react-router";
import {
  MedigapQuoteActionTypes,
  MedigapQuoteContext,
  withMedigapQuoteContextProvider
} from "./MedigapQuoteContextProvider";
import {IQuestion} from "../../shared/SidePanel/AscendStepper";
import {AppStateContext} from "../../shared/AppStateContext";

function MedigapQuote(props: RouteComponentProps<{routeName: string}>) {
  const [{currentStep}, dispatchState] = React.useContext(AppStateContext);
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);
  const {medigapState, setMedigapState} = useStepperState();

  React.useEffect(() => {
    dispatch({type: MedigapQuoteActionTypes.SAVE_STEP_VALUE, payload: {
        stateKey: 'QUIZ_3', form: 'QUIZ_3', medicarePlanType: {
          questionText: 'Medicare plan type',
          answerText: 'MG',
          value: 'MG'
        }
      }})
  }, []);

  const Component = React.useMemo(() => {
    const step = medigapState.questions.find((route: IQuestion) => currentStep.includes(route.link));
    if (step) {
      const C = step.component;
      return <C />
    } else {
      return null;
    }
  }, [currentStep]);

  return <Box mt={'8px'} minHeight={'700px'} id={'wrapper'} position={'relative'}>
    {Component}
    </Box>;
}

export default React.memo(withMedigapQuoteContextProvider(MedigapQuote));
