import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step10";
import {getMedigapQuoteId, getQuoteId} from "../../../shared/QuoteId";
import {config} from "../../config/config";

function Step10(props: RouteComponentProps<any>) {

  return <Shared onNextStep={() => {
    const quoteParam = !!getMedigapQuoteId() ? "&medigapQuote=" + getMedigapQuoteId() : '';
    if (document.location.search) {
      document.location.href = (config.medigapUrl as string) + 'plans' + document.location.search +`&skipCreateAccount=true${quoteParam}`;
    } else {
      document.location.href = (config.medigapUrl as string) + 'plans' + `?skipCreateAccount=true${quoteParam}`;
    }
  }} />;
}

export default Step10 as any;
