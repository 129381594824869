import React from 'react';
import {PharmacyLocationDistanceOutput} from "../../../types";
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";

export default function usePharmaciesByRequestId(requestId?: string) {
  const [pharmacies, setPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>();
  const [filled, setFilled] = React.useState<boolean>();

  const [getRequest, {loading, refetch, called}] = useQuestionnaireRequest(async data => {
    setFilled(data.preferredPharmacies?.preferredPharmaciesFilled);
    if (data.preferredPharmacies?.preferredPharmacies?.length) {
      setPharmacies(data.preferredPharmacies?.preferredPharmacies);
    }
  })

  React.useEffect(() => {
    if (requestId) {
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {pharmacies, setPharmacies, loading, refetch, filled, setFilled, called};
}
