import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step9a";
import {QuoteContext} from "../QuoteContextProvider";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";

function Step9a(props: RouteComponentProps<any>) {
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [quote, dispatch] = React.useContext(QuoteContext);

  const onNextStep = React.useCallback(() => {
     dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step10}});
  }, [document.location.search, quote]);

  return <Shared quote={quote} dispatch={dispatch}
                 onNextStep={onNextStep}
                 {...props} />;
}

export default Step9a as any;
