import React from "react";
import {RouteComponentProps, useHistory} from "react-router";
import Shared from "../../shared/questions/Step9a";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import SearchingPlanModal from "../components/SearchingPlanModal";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";

function Step9a(props: RouteComponentProps<any>) {
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  return <>
    <SearchingPlanModal
      countyName={quote.STEP_2?.countyName + ' County, ' + quote.STEP_2?.state}
      onClose={() => dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MGquestionnaire + "/" + questionsRoutes.step9}})}
      open={open} />
    <Shared quote={quote} dispatch={dispatch}
                 onNextStep={() => setOpen(true)}
                 {...props} />
  </>;
}

export default Step9a as any;
