import React from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {Box, Typography} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import {RouteComponentProps} from "react-router";

type Step10Props = RouteComponentProps<any> & {onNextStep: () => void};

function Step10(props: Step10Props) {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const onClick = () => {
    props.onNextStep()
  }

  return <div>
    <Typography color={'textSecondary'} className={'bold fs-17 mt-20'} align={'center'}>Don't want to wait? Connect now:</Typography>
    <Typography color={'textPrimary'} className={'thin fs-30 mv-10'} align={'center'}>+1 (888) 969-7667</Typography>
    <Typography color={'textSecondary'} className={'fs-17 mb-30 medium'} align={'center'}>TTY Users 711</Typography>
    <Box display={'flex'} justifyContent={'center'}>
      <AscendButton onClick={onClick} className={'gtm-step-event bg-orange'} variant={'contained'}>See Plan Options Online</AscendButton>
    </Box>
  </div>;
}

export default withTitle(
  Step10,
  'A licensed Medicare Professional\nwill be calling you soon!',
  '',
  undefined,
  undefined,
  isMobile => isMobile,
)
