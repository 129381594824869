import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {DrugInfoWithGeneric} from "../../types";
import AscendModal from "./AscendModal";
import AscendButton from "./AscendButton";
import {config} from "../../Quote/config/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
    },
    drug: {
      color: colors.custom.green.variant2,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 22
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    }
  })
);

interface ConfirmBrandedDrugModalProps {
  open: boolean,
  drug?: DrugInfoWithGeneric,
  onClose: (useGeneric?: boolean) => void,
}

export default function ConfirmBrandedDrugModal(props: ConfirmBrandedDrugModalProps) {
  const classes = useStyles();

  return <AscendModal
    width={590}
    open={props.open}
    onClose={() => props.onClose()}
    paperPadding={'25px 45px 40px'}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={(config.imagesUrl as string) + '/img/close.svg'} /></IconButton>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>You selected a branded drug.</Typography>
      <div className={'h-20'} />
      <Typography variant={'h1'} color={'textPrimary'} className={classes.drug}>{props.drug?.name}</Typography>
      <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>has a lower cost generic version called</Typography>
      <Typography variant={'h1'} color={'textPrimary'} className={classes.drug}>{props.drug?.genericName}</Typography>
      <div className={'h-25'} />
      <Typography color={'textPrimary'} className={'bold fs-18 text-align-centered'}>Would you like to add the generic version to<br />your list instead?</Typography>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'10px'}>
        <AscendButton variant={'contained'} onClick={() => props.onClose(true)}>Add generic drug</AscendButton>
        <Box pt={'20px'}>
          <Typography variant={'h4'} className={`${classes.link} branded-drug-button`} onClick={() => props.onClose(false)}>Continue with branded</Typography>
        </Box>
      </Box>
    </>
  </AscendModal>
}
