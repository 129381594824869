import React from "react";
import {Gender} from "../medigap-types";

export const AppStateContext = React.createContext<[IAppState, (action: IAppStateAction) => void]>([] as any);

export const AppStateContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<(state: IAppState, action: IAppStateAction) => IAppState>
  (reducer, {currentStep: '', hideBackButton: false});

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppStateContext.Provider>
  );
};

export const withAppStateContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <AppStateContextProvider>
      <WrappedComponent {...{...props}} />
    </AppStateContextProvider>
  )
}

const reducer = (state: IAppState, action: IAppStateAction) => {
  let newState: IAppState = {...state};
  switch (action.type) {
    case AppStateActionTypes.UPDATE:
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case AppStateActionTypes.BACK:
      newState = {
        ...state,
        // currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0
      };
      break;
    default:
      throw new Error();
  }

  return newState;
};

interface IAppState {
  currentStep: string,
  hideBackButton: boolean,
  age?: number,
  gender?: Gender,
  medicareType?: IMedicareType,
  zip?: string,
  county?: string,
}

export enum AppStateActionTypes {
  UPDATE,
  BACK
}

interface IAppStateAction {
  payload?: any,
  type: AppStateActionTypes
}

export enum IMedicareType {
  MA = 'MA',
  MG = 'MG'
}
