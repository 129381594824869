import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step3";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";

function Step3(props: RouteComponentProps<any>) {
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);
  const ref = React.useRef(null);

  React.useEffect(() => {
      if ((window as any).track) {
          (window as any).track('Page View - Widget Flow - Birthdate', {Marketplace: 'MG'});
      }
  }, [])

  return <div ref={ref}>
    <Shared quote={quote} dispatch={dispatch}
            onNextStep={() => {
             dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MGquestionnaire + '/' + questionsRoutes.step9a}})
            }}
            onPrevStep={() => {
              dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MGquestionnaire + '/' + questionsRoutes.step2}})
            }}
            {...props} />
  </div>;
}

export default Step3 as any;
