import React from "react";
import {client} from "../../shared/ApolloClient";
import {gql} from "apollo-boost";
import {getMedigapQuoteId, getQuoteId, setMedigapQuoteId, setQuoteId} from "../../shared/QuoteId";
import * as _ from 'lodash';
import {MedigapFilterStateInput} from "../../medigap-types";
import moment from "moment";
import {getStoredUser} from "../../shared/StoredUser";
import {getCId} from "../../shared/utils";

export const MedigapQuoteContext = React.createContext<[IMedigapQuoteState, (action: IMedigapQuoteAction) => void]>([] as any);

export const MedigapQuoteContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<(state: IMedigapQuoteState, action: IMedigapQuoteAction) => IMedigapQuoteState>
  (reducer, {} as any);

  return (
    <MedigapQuoteContext.Provider value={[state, dispatch]}>
      {props.children}
    </MedigapQuoteContext.Provider>
  );
};

export const withMedigapQuoteContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <MedigapQuoteContextProvider>
      <WrappedComponent {...{...props}} />
    </MedigapQuoteContextProvider>
  )
}

const reducer = (state: IMedigapQuoteState, action: IMedigapQuoteAction) => {
  let newState: IMedigapQuoteState = {...state};
  let filters: any = undefined;
  switch (action.type) {
    case MedigapQuoteActionTypes.INIT_STATE:
      if (!action.payload) {
        const stored = localStorage.getItem('medigapQuote')
        if (stored) {
          action.payload = JSON.parse(stored);
        }
      }
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case MedigapQuoteActionTypes.SAVE_STEP_VALUE:
    case MedigapQuoteActionTypes.SAVE_STEP_VALUE_SKIP_SERVER_SYNC:
      const {stateKey, ...payload} = action.payload;
      if (payload?.form === 'STEP_6' && action.payload.benefits?.value) {
        filters = {
          extraBenefits: action.payload.benefits?.value,
        }
      }
      newState = {
        ...state,
        [stateKey]: payload,
      };
      break;
    default:
      throw new Error();
  }
  if (action.type !== MedigapQuoteActionTypes.INIT_STATE && newState.STEP_3?.gender && newState.STEP_3?.tobacco) {
    client.mutate({
      mutation: gql(saveMedigapQuoteMutation),
      variables: {
        input: {
          medigapFilterState: prepareMedigapToSave(newState),
          cId: getCId(),
          id: getMedigapQuoteId(),
        }
      }
    }).then((data: any) => {
      setMedigapQuoteId(data.data.saveMedigapQuote?.mgQuoteId);
    })
  }
  localStorage.setItem('medigapQuote', JSON.stringify(newState))

  return newState;
};


function prepareMedigapToSave(state: IMedigapQuoteState): MedigapFilterStateInput {
  return {
    zip: state.STEP_2?.zip,
    county: state.STEP_2?.countyName,
    age: state.STEP_3?.age || getAgeByBirthdate(getStoredUser()?.birthDate),
    gender: state.STEP_3?.gender,
    tobacco: state.STEP_3?.tobacco,
  }
}

export function getAgeByBirthdate(birthdate?: string): number {
  const age = Math.floor(moment.duration(moment().diff(moment(birthdate))).asYears());
  if (age > 62 && age < 66) {
    return 65; // Richard asked to return 65 if person is 63 and over
  }
  return age;
}

function prepareToSave(state: IMedigapQuoteState) {
  let result: any = {customAnswers: {}};
  for (const key in state) {
    if (key.indexOf('STEP_') === 0 || key.indexOf('QUIZ_') === 0 ) {
      result.customAnswers = {...result.customAnswers, [key]: state[key as keyof IMedigapQuoteState]};
    } else {
      if (_.isObject(state[key as keyof IMedigapQuoteState])) {
        result = {...result, ...(state[key as keyof IMedigapQuoteState] as any)};
      } else  {
        result = {...result, [key]: state[key as keyof IMedigapQuoteState]};
      }
    }
  }
  delete result.pin;
  delete result.extraBenefits;
  delete result.countyName;
  result.customAnswers = JSON.stringify(result.customAnswers);
  return result;
}


const saveMedigapQuoteMutation = `
mutation ($input: MedigapQuoteInput!) {
  saveMedigapQuote(input: $input) {
    clientId
    mgQuoteId
  }
}
`;

export enum MedigapQuoteActionTypes {
  INIT_STATE,
  SAVE_STEP_VALUE,
  SAVE_STEP_VALUE_SKIP_SERVER_SYNC,
}

interface IMedigapQuoteAction {
  payload?: any,
  type: MedigapQuoteActionTypes
}

export interface IMedigapQuoteState {
  id?: string,
  county?: string,
  pin?: string;
  STEP_1?: {
    value?: any
  },
  STEP_2?: {
    zip?: any,
    countyName?: any,
    state?: any,
  },
  STEP_3?: {
    tobacco?: any,
    gender?: any,
    age?: any,
  },
  QUIZ_3?: {
    medicarePlanType: {
      value?: string
    },
  },
  QUIZ_2?: {
    prepay?: any,
    certainDoctors?: any
    drugsCoverage?: any
    receiveMedicaid?: any
    extraBenefits?: any
    monthlyBudget?: any
  },
  STEP_4?: {
    value?: any,
  },
  STEP_6?: {
    monthlyCost?: any,
    benefits?: any,
  },
  STEP_8?: {
    value?: any,
  },
}
