const SID_KEY = 'USER';

export function getStoredUser(): IStoredUser | undefined {
  let stored = sessionStorage.getItem(SID_KEY) || localStorage.getItem(SID_KEY);
  if (!stored) {
    return undefined
  }
  return JSON.parse(stored);
}

export function setStoredUser(user: IStoredUser) {
  sessionStorage.setItem(SID_KEY, JSON.stringify(user));
}

export function resetStoredUser(): void {
  sessionStorage.removeItem(SID_KEY);
}


export interface IStoredUser {
  userId?: string,
  profileId?: string,
  enrollmentId?: string,
  quoteId?: string,
  medigapQuoteId?: string,
  firstName?: string,
  lastName?: string,
  tobacco?: string,
  email?: string,
  phoneNumber?: string,
  birthDate?: string,
  zip?: string,
  gender?: string,
  countyName?: string,
  plansFound?: number,
}

