import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
  custom: {
    green: {
      variant1: string,
      variant2: string,
      variant3: string,
    }
  }
} = {
  text: {
    primary: '#1C434F',
    secondary: '#1c434f99',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "#76A9FF",
    "500": "#4F91FF",
    "600": "#2979FF",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "#133774",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  },
  custom: {
    green: {
      variant1: '#5ECB9E',
      variant2: '#029094',
      variant3: '#06B9BE',
    }
  }
};

export default createMuiTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.secondary["400"],
      main: colors.secondary["700"],
      dark: colors.secondary["900"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#164d7b',
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(28,67,79,0.5)',
      }
    },
    MuiFormHelperText: {
      contained: {
        marginTop: 10,
      }
    },
    MuiBadge: {
      badge: {
        height: 15,
        width: 15,
        minWidth: 15,
        fontSize: 12
      },
      colorPrimary: {
        backgroundColor: colors.custom.green.variant2
      },
      anchorOriginBottomRightCircle: {
        right: '30%',
        bottom: '33%'
      }
    },
    MuiInputAdornment: {
      root: {
        marginTop: 3,
        '& p': {
          fontSize: 16
        }
      }
    }
  },
  typography: {
    fontWeightBold: 500,
    fontFamily: [
      'Sailec', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
    h1: {
      // fontSize: 50,
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32.5px'
    },
    h2: {
      fontSize: '24px',
      // fontSize: 32,
      fontWeight: 400
    },
    h3: {
      fontSize: '18px',
      fontWeight: 400,
    } ,
    h4: {
      fontSize: '16px',
      fontWeight: 400,
    },
    //Callout
    h5: {
      fontSize: '12px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '10px',
      fontWeight: 400,
    },
    // subtitle2: {
    //   fontSize: 10,
    //   lineHeight: '12px',
    //   color: '#A7A7A7',
    //   textTransform: "uppercase",
    //   marginTop: '4px',
    // } as any,
    body1: {
       fontSize: 13,
    },
    body2: {
       fontSize: 14,
    },
  },
});
