import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step9";
import {MedigapQuoteContext} from "../MedigapQuoteContextProvider";
import {withTitle} from "../../../shared/components/withTitle";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";
import {config} from "../../config/config";

function Step9(props: RouteComponentProps<any>) {
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [quote, dispatch] = React.useContext(MedigapQuoteContext);

  React.useEffect(() => {
      if ((window as any).track) {
          (window as any).track('Page View - Widget Flow - Personal info', {Marketplace: 'MG'});
      }
  }, [])

  return <Shared quote={quote} dispatch={dispatch}
                 onNextStep={() => {
                     document.location.href = config.userFlowUrl + 'conversion-mg'
                   // dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MGquestionnaire + "/" + questionsRoutes.step10}})
                 }}
                 {...props} />;
}

export default withTitle(
  Step9,
  'One last step.',
  'Provide your details so we can reach you.',
  undefined,
  undefined,
  isMobile => isMobile,
)

const getPlans = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!) {
    medigapPlans(filter: $filter, page: $page) {
      totalElements
    }
}
`;
