import React from "react";
import {Box} from "@material-ui/core";
import {QuoteActionTypes, QuoteContext, withQuoteContextProvider} from "./QuoteContextProvider";
import useQuestionnaireRequest from "../../shared/hooks/useQuestionnaireRequest";
import useStepperState from "./hooks/useStepperState";
import {AppStateContext} from "../../shared/AppStateContext";
import {IQuestion} from "../../shared/SidePanel/AscendStepper";

function Quote() {
  const [{currentStep}, dispatchState] = React.useContext(AppStateContext);
  const [{pin}, dispatch] = React.useContext(QuoteContext);
  const {state, setState} = useStepperState();

  const [getRequest, requestData] = useQuestionnaireRequest(data => {
    dispatch({
      type: QuoteActionTypes.INIT_STATE,
      payload: data,
    })
  })

  React.useEffect(() => {
    dispatch({type: QuoteActionTypes.SAVE_STEP_VALUE, payload: {
        stateKey: 'QUIZ_3', form: 'QUIZ_3', medicarePlanType: {
          questionText: 'Medicare plan type',
          answerText: 'MA',
          value: 'MA'
        }
      }})
  }, []);

  const Component = React.useMemo(() => {
    const step = state.questions.find((route: IQuestion) => currentStep.includes(route.link));
    if (step) {
      const C = step.component;
      return <C />
    } else {
      return null;
    }
  }, [currentStep]);

  return <Box mt={'8px'} id={'wrapper'} position={'relative'}>
       {Component}
    </Box>;
}

export default React.memo(withQuoteContextProvider(Quote));
