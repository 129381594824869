import React from 'react';
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import {CollapseTrigger} from "../../../shared/components/CollapsableCard";
import useCommonStyles from "../../../shared/useCommonStyles";
import FormTextInput, {TickAdornment} from "../../../shared/components/FormTextInput";
import AscendButton from "../../../shared/components/AscendButton";
import {colors} from "../../../shared/AppTheme";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import {getStoredUser} from "../../../shared/StoredUser";
import {CallButton} from "../../../shared/components/HelpModal";
import {config} from "../../config/config";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingRight: 0
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500,
    },
    checkboxWrapper: {
      alignItems: 'flex-start',
      backgroundColor: 'transparent'
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    textLink: {
      color: colors.text.primary,
    }
  }),
);

interface FormState {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const Schema = yup.object().shape({
  firstName: yup.string().required("Please enter a valid first name").typeError('Please enter a valid first name'),
  lastName: yup.string().required("Please enter a valid last name").typeError('Please enter a valid last name'),
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
  phoneNumber: yup.string().required("Please enter a valid phone").min(11, "Please enter a valid phone"),
});

type CreateProfileFormProps = {
  onSubmit:(values: FormState) => void,
  onCancelClick:() => void,
  phoneFirst?: boolean,
  submitButtonLabel: string,
  emailError?: string,
  backButtonLabel: string,
  buttonWrapperClassName?: string,
  disabled?: boolean,
}

export default function CreateProfileForm(props: CreateProfileFormProps) {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const {register, handleSubmit, errors, watch, formState, getValues, control} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
      email: getStoredUser()?.email,
      firstName: getStoredUser()?.firstName,
      lastName: getStoredUser()?.lastName,
      phoneNumber: getStoredUser()?.phoneNumber
    }
  });
  watch()

  const emailField = <FormTextInput fullWidth
                                    label={'Email address'}
                                    name={'email'}
                                    inputProps={{
                                      autoCapitalize: 'none'
                                    }}
                                    InputProps={{
                                      endAdornment: ((!errors.email && getValues().email?.length > 0 && formState.touched.email) && !props.emailError) ?
                                        <TickAdornment/> : null
                                    }}
                                    placeholder={'Enter your email address'}
                                    error={(!!errors.email && formState.touched.email) || !!props.emailError}
                                    helperText={(formState.touched.email && errors?.email?.message) || props.emailError}
                                    inputRef={register} />
  const phoneField = <Controller
    control={control}
    name={'phoneNumber'}
    render={({ onChange }) => <PhoneNumberInput defaultValue={getStoredUser()?.phoneNumber}
                                                label={'Phone number'}
                                                name={'phoneNumber'}
                                                onChange={onChange} />}
  />

  return <form onSubmit={handleSubmit(props.onSubmit)} autoComplete={'off'} className={'t-base-info-form'}>
    <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box className={classes.wrapper}>
        <Box className={classes.nameWrapper} >
          <FormTextInput fullWidth
                         label={'First Name'}
                         name={'firstName'}
                         autoFocus={true}
                         error={formState.touched.firstName && !!errors.firstName}
                         helperText={formState.touched.firstName && errors?.firstName?.message}
                         InputProps={{
                           endAdornment: (formState.touched.firstName && !errors.firstName && getValues().firstName?.length > 0) ?
                             <TickAdornment/> : null
                         }}
                         inputRef={register}
                         placeholder={'First name'} />
          <div className={'w-20'} />
          <FormTextInput fullWidth
                         label={'Last Name'}
                         name={'lastName'}
                         error={formState.touched.lastName && !!errors.lastName}
                         helperText={formState.touched.lastName && errors?.lastName?.message}
                         InputProps={{
                           endAdornment: (formState.touched.lastName && !errors.lastName && getValues().lastName?.length > 0) ?
                             <TickAdornment/> : null
                         }}
                         inputRef={register}
                         placeholder={'Last name'} />
        </Box>
        <Collapse in={!!getValues().firstName && !!getValues().lastName}>
          {props.phoneFirst ? phoneField : emailField}
        </Collapse>
        <Collapse in={props.phoneFirst ?
          !!getValues().phoneNumber && !errors.phoneNumber && getValues().phoneNumber.length === 10 :
          !!getValues().email && !errors.email}>
          {props.phoneFirst ? emailField : phoneField}
        </Collapse>
        <Box display={'flex'} alignItems={'center'} className={'mb-35'}>
          <img src={(config.imagesUrl as string) + '/img/padlock.svg'} />
          <Typography className={'bold fs-14 ml-8'} color={'textPrimary'}>100% secure. We’ll never sell your details.</Typography>
        </Box>
      </Box>
    </Box>
    <Box className={clsx(commonClasses.buttonWrapper, props.buttonWrapperClassName)} mt={'10px'} mb={'30px'}>
      {/*<Box display={'flex'} justifyContent={'center'} mt={2}>
        <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
          props.onCancelClick();
        }} color={'secondary'}>{props.backButtonLabel}</AscendButton>
      </Box>*/}
      <div className={'h-16'} />
      <AscendButton tabIndex={4} disabled={!formState.isValid || props.disabled}
                    className={'gtm-step-event gtm-button-event'}
                    data-gtm-button-name={'CREATE_PROFILE_BUTTON'}
                    variant={'contained'} type={'submit'}>{props.submitButtonLabel}</AscendButton>
    </Box>

    <div className={'ph-35 mt-16'}>
      <Typography className={'fs-11'} color={'textPrimary'}>By submitting my email and phone number and clicking ‘{props.submitButtonLabel}’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight Inc. at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} className={classes.textLink} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} className={classes.textLink} target={'_blank'}>Terms of Use</a>.<br /><br />

        I understand my consents to receive text messages and telephone calls are not required to receive services from CoverRight Inc.; I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 and I can revoke my consents at any time by emailing hello@coverright.com.</Typography>
    </div>
  </form>
}
