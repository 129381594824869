import React, {ReactNode} from "react";
import {withTitle} from "../../../shared/components/withTitle";
import {Box, createStyles, RadioGroup, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import AscendButton from "../../../shared/components/AscendButton";
import paths from "../../config/router-paths";
import useCommonStyles from "../../../shared/useCommonStyles";
import AscendTitle from "../../../shared/components/AscendTitle";
import {useLazyQuery} from "@apollo/react-hooks";
import {ExtraBenefit, FilterItem, QueryPlanTypesArgs} from "../../../types";
import {gql} from "apollo-boost";
import AscendCheckbox from "../../../shared/components/AscendCheckbox";
import * as _ from 'lodash';
import {makeStyles} from "@material-ui/core/styles";
import {QuoteActionTypes, QuoteContext} from "../../MedicareAdvantage/QuoteContextProvider";
import {storeFilters} from "../../../shared/utils";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";
import {config} from "../../config/config";
import {questionsRoutes} from "../../MedicareAdvantage/hooks/useStepperState";
import useDimensions from "../../../shared/hooks/useDimensions";
import {ProfileSource} from "../../../enrollment-types";
import {getStoredUser} from "../../../shared/StoredUser";

const formName = 'STEP_6';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flexDirection: 'row!important' as any
    },
    column: {
      flexDirection: 'column!important' as any,
    },
    radio: {
      marginLeft: 16,
      display: 'flex',
      flex: 1,
    },
    radioPhone: {
      marginLeft: 0,
      marginTop: 16
    },
  }),
);


function Benefits() {
  const [state, dispatch] = React.useContext(QuoteContext);
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const classes = useStyles();
  const [values, setValues] = React.useState<string[]>(state?.STEP_6?.benefits?.value || []);
  const [ref, {width, height}] = useDimensions();


  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('Page View - Widget Flow - AdditionalExtraBenefits', {Marketplace: 'MA'});
    }
    (window as any).gtm_step_name = formName;
  }, [])

  const toggleValue = (val: string) => {
    if (values?.includes(val)) {
      setValues(prev => _.without(prev, val))
    } else {
      setValues(prev => [...(prev || []), val])
    }
  }

  const disabled = React.useMemo(() => {
      return values?.length > 2;
  }, [values]);

  const phone = React.useMemo(() => {
      return width < 518;
  }, [width]);

  const onSubmit = () => {
    /*dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName, benefits: {
          questionText: 'What extra benefits would you like?',
          answerText: values.map(_.lowerCase).map(_.startCase).join(', '),
          value: values
        },
      }
    })
    if ((window as any).track) {
      (window as any).track('First time MA - choose extra benefit', {source: ProfileSource.UserFlowV1_5}, true);
    }*/

    /*const serialize = function(obj: any) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== '') {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }
    const url = config.userFlowUrl + 'info?' + serialize({
      dob: getStoredUser()?.birthDate,
      quote: getStoredUser()?.quoteId,
      zip: getStoredUser()?.zip,
      county: getStoredUser()?.countyName,
      flow: 'ma',
      source: ProfileSource.UserFlowV1_5
    });

    document.location.href = url;*/

    dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step9}})
  };

  return <div ref={ref}>
    <div className={'h-32'} />
    <AscendTitle title={'Select three features that matter to you most:'} />
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" classes={{root: phone ? classes.column : classes.row}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Dental'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Dental) < 0}
                        tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                        checked={values && values.includes(ExtraBenefit.Dental) } onChange={() => toggleValue(ExtraBenefit.Dental)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/implant.svg'} /></Box>}
        />
      </Box>
      <Box className={`${classes.radio} ${phone ? classes.radioPhone : ''} `} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Vision'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Vision) < 0}
                        tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                        checked={values && values.includes(ExtraBenefit.Vision) } onChange={() => toggleValue(ExtraBenefit.Vision)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/glasses.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" classes={{root: phone ? classes.column : classes.row}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Hearing'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Hearing) < 0}
                        tooltip={'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'}
                        checked={values && values.includes(ExtraBenefit.Hearing) } onChange={() => toggleValue(ExtraBenefit.Hearing)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/hear.svg'} /></Box>}
        />
      </Box>
      <Box className={`${classes.radio} ${phone ? classes.radioPhone : ''} `} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Fitness'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Fitness) < 0}
                        tooltip={'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'}
                        checked={values && values.includes(ExtraBenefit.Fitness) } onChange={() => toggleValue(ExtraBenefit.Fitness)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/weightlifting.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" classes={{root: phone ? classes.column : classes.row}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Insulin savings'}
                        disabled={disabled && values.indexOf(ExtraBenefit.InsulinSavings) < 0}
                        tooltip={'Plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                        checked={values && values.includes(ExtraBenefit.InsulinSavings) } onChange={() => toggleValue(ExtraBenefit.InsulinSavings)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/insulin.svg'} /></Box>}
        />
      </Box>
      <Box className={`${classes.radio} ${phone ? classes.radioPhone : ''} `} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Over-the-counter Allowance'}
                        disabled={disabled && values.indexOf(ExtraBenefit.OverTheCounter) < 0}
                        tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                        checked={values && values.includes(ExtraBenefit.OverTheCounter) } onChange={() => toggleValue(ExtraBenefit.OverTheCounter)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/drugs.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" classes={{root: phone ? classes.column : classes.row}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Telehealth'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Telehealth) < 0}
                        tooltip={'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'}
                        checked={values && values.includes(ExtraBenefit.Telehealth) } onChange={() => toggleValue(ExtraBenefit.Telehealth)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/phone-call.svg'} /></Box>}
        />
      </Box>
      <Box className={`${classes.radio} ${phone ? classes.radioPhone : ''} `} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Transportation'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Transportation) < 0}
                        tooltip={'Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                        checked={values && values.includes(ExtraBenefit.Transportation) } onChange={() => toggleValue(ExtraBenefit.Transportation)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/ambulance.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup className={`${phone ? classes.column : classes.row} flex-justify-center`} row={true}>
      <Box display={'flex'} width={!phone ? '50%' : undefined}>
        <AscendCheckbox variant={'outlined'} label={'Part B Premium Giveback'}
                        disabled={disabled && values.indexOf(ExtraBenefit.PartBPremiumGiveback) < 0}
                        tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                        checked={values && values.includes(ExtraBenefit.PartBPremiumGiveback) } onChange={() => toggleValue(ExtraBenefit.PartBPremiumGiveback)}
                        endAdornment={<Box mr={'12px'} className={'w-30 h-30'}><img src={(config.imagesUrl as string) + '/img/part-b-giveback.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
        <AscendButton className={'gtm-step-event'} id={formName}
                      variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <AscendButton variant={'contained'} onClick={() => {
            dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step3}})
          }} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
    </Box>
  </div>;
}

export default withTitle(
  Benefits,
  () => 'Any extra benefits?',
  '',
  undefined,
  undefined,
  isMobile => isMobile,
);
