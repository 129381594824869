import React from "react";
import {RouteComponentProps} from "react-router";
import Shared from "../../shared/questions/Step2";
import {QuoteContext} from "../QuoteContextProvider";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";

function Step2(props: RouteComponentProps<any>) {
  const [quote, dispatch] = React.useContext(QuoteContext);
  const [appState, dispatchState] = React.useContext(AppStateContext);

  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('Page View - Widget Flow - Zip', {Marketplace: 'MA'});
    }
  }, [])

  return <Shared quote={quote}
                 dispatch={dispatch}
                 onNextStep={() => dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step3}})}
                 {...props} />;
}

export default Step2 as any;
