import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {UserProfileInput, UserProfileSaveOutput} from "../../../enrollment-types";
import {client} from "../../../shared/EnrollmentApolloClient";

export default function useSaveUserProfile(options?: MutationHookOptions<UserProfileSaveOutput, {data: UserProfileInput}>) {
  return useMutation<any, {data: UserProfileInput}>(gql(saveUserInfoMutation), {
    client: client
  })
}

const saveUserInfoMutation = `
mutation ($data: UserProfileInput!) {
  saveUserProfile(input: $data) {
    enrollmentId
    profileId
    userId
    userToken {
      accessToken
      idToken
      refreshToken
    }
  }
}
`;
