import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {colors} from "../../../shared/AppTheme";
import {makeStyles} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import useCommonStyles from "../../../shared/useCommonStyles";
import {DrugOutput} from "../../../types";
import {getQuoteId} from "../../../shared/QuoteId";
import paths from "../../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {DrugSelection} from "../../../shared/components/DrugSelection";
import {withTitle} from "../../../shared/components/withTitle";
import AscendButton from "../../../shared/components/AscendButton";
import AscendInfo from "../../../shared/components/AscendInfo";
import useDrugsByRequestId from "../hooks/useDrugsByRequestId";
import {AppStateActionTypes, AppStateContext} from "../../../shared/AppStateContext";

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 22,
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 20
  },
  addMedicationButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px dashed #D6DFDA',
    background: '#F5F7F6',
    borderRadius: 8,
    width: '100%',
    paddingTop: 27,
    paddingBottom: 23,
    color: colors.custom.green.variant1,
    fontWeight: 700,
    fontSize: 16,
  }
});

const formName = 'STEP_5';

function Step5() {
  const classes = useStyles();
  const [appState, dispatchState] = React.useContext(AppStateContext);
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [selectedPrescriptions, setSelectedPrescriptions] = React.useState<DrugOutput[]>([]);
  const [showAutocomplete, setShowAutocomplete] = React.useState(true);

  const {drugs} = useDrugsByRequestId(getQuoteId());

  React.useEffect(() => {
    if (drugs.length) {
      setSelectedPrescriptions(drugs);
      setShowAutocomplete(false);
    }
  }, [drugs])

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {
        preferredDrugs: selectedPrescriptions,
        form: formName,
        preferredDrugsFilled: true,
        stateKey: 'preferredDrugs',
      }
    })
    if ((window as any).track) {
      (window as any).track('First time MA - add drug', {source: 'V1.5'});
    }
    if (selectedPrescriptions.length > 0) {
      dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step5a}})
    } else {
      dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step6}})
    }
  };

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'40px'}>
      <Box display={'flex'} flexDirection={'column'} flex={1} mt={'30px'}>
        {!!selectedPrescriptions.length && <Box mb={'23px'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Your selected prescriptions:</Typography>
        </Box>}
        <DrugSelection selectedPrescriptions={selectedPrescriptions}
                       setSelectedPrescriptions={setSelectedPrescriptions}
                       showAutocomplete={showAutocomplete}
                       setShowAutocomplete={setShowAutocomplete}
        />
      </Box>
      {showAutocomplete && <Box mt={'-10px'}>
        <Typography variant={'h4'} color={'textSecondary'} className={classes.title}>Can’t find your drugs? Call us and we can help.</Typography>
      </Box>}
      <div className={'h-10'} />
      <AscendInfo open={true}
                  description={<>
                    <p style={{marginTop: 0, marginBottom: 0, color: colors.text.primary}}><strong>We’ll help you save.</strong> If you add a branded drug, we’ll suggest a generic alternative and give you the option to choose it instead.</p>
                  </>}/>
    </Box>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step4}})
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    disabled={!selectedPrescriptions.length}
                    variant={'contained'}
                    onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        if ((window as any).track) {
          (window as any).track('First time MA - skip drug', {source: 'V1.5'});
        }
        dispatchState({type: AppStateActionTypes.UPDATE, payload: {currentStep: paths.MAquestionnaire + '/' + questionsRoutes.step6}})
      }}>Click here to skip this step</Typography>
    </Box>
  </>
}

export default withTitle(
  Step5,
  'Do you have prescription medication needs?',
  'Enter for your prescriptions below and we’ll find plans that they’re covered under.'
)

